var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5f91161f76620a4c034a7255c25084bedd2d08f9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
	dsn: "https://3117e429f2f94c00a4fda6c771876510@o1253910.ingest.sentry.io/4505528403886080",
	tracesSampleRate: 1.0, // Capture 100% of the transactions
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  });